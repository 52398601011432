



















































// TODO: Trans
import Vue from "vue";
import { Component } from "vue-property-decorator";
import PurchaseOverview from "./PurchaseOverview.vue";
import CreateCompanyForm from "./CreateCompanyForm.vue";
import BillingDetailsForm from "./BillingDetailsForm.vue";

import { Stripe, loadStripe } from "@stripe/stripe-js";
import * as pmTypes from "./pmTypes";

const stripeKey = process.env.VUE_APP_STRIPE_KEY as string;
@Component({
  components: {
    CreateCompanyForm,
    BillingDetailsForm,
    PurchaseOverview,
  },
})
export default class CreateCompany extends Vue {
  step = 1;
  maxEmployees = 5;
  createDialog = false;
  stripeLoading = false;
  stripe: Stripe | null = null;
  paymentMethodType = pmTypes.card;
  paymentMethodId = "";
  async load() {
    this.stripeLoading = true;
    this.stripe = await loadStripe(stripeKey);
    this.stripeLoading = false;
  }

  created() {
    this.load();
  }
}
